<template>
  <!-- 大事记 -->
  <div class="common-wrap">
    <headNav active="3"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/other-14.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img
          src="@/assets/version2/otherEn-14.png"
          alt=""
          srcset=""
          class="img"
          v-else
        />
      </div>
      <div class="contents">
        <div class="dot dot-right"></div>
        <div class="dot dot-left"></div>
        <div class="w1200 main">
          <div class="bg-line"></div>
          <div class="item-list">
            <div
              class="flex between item item-1"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="left">
                <div
                  class="date-1"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('../assets/version2/dsj-' +
                        templateTime(item.eventtime, 'year') +
                        '.png') +
                      ')',
                  }"
                ></div>
                <div class="date-2">{{ templateTime(item.eventtime) }}</div>
              </div>
              <div class="right">
                <div class="bg-right"></div>
                <div
                  class="bg-img"
                  :style="{
                    backgroundImage: 'url(' + state.baseUrl + item.thumb + ')',
                  }"
                ></div>
                <div class="en-img"></div>
                <div class="name">{{ item.title }}</div>
                <div class="text ellipsis2">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from "moment";
export default {
  components: { headNav, footNav },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$ajax.get("/cms/api/bigevent", {}).then((res) => {
        if (res.code == "200") {
          this.list = res.data || [];
        }
      });
    },
    templateTime(time, type) {
      if (!time) return;
      let formatDate = moment(time * 1000).format("YYYY-MM-DD");
      let year = formatDate.slice(0, 4);
      let mon = formatDate.slice(5, 7);
      if (type) {
        return year;
      } else {
        return year + "年" + mon + "月";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .contents {
    position: relative;
    .dot {
      height: 648px;
      position: absolute;
      z-index: 99;
      background-image: url("../assets/version2/other-13.png");
      background-size: 100% 100%;
    }
    .dot-left {
      width: 284px;
      left: 0;
      top: 595px;
    }
    .dot-right {
      width: 315px;
      top: 13px;
      right: 0;
      transform: rotateY(180deg);
    }
    .main {
      position: relative;
      padding-top: 1px;
      padding-bottom: 1px;
      .bg-line {
        width: 213px;
        height: 100%;
        background-image: url("../assets/version2/other-15.png");
        background-size: 100% 100%;
        position: absolute;
        left: 235px;
        top: 0;
        z-index: 0;
      }
      .item-list {
        position: relative;
        z-index: 1;
      }
      .item {
        margin: 250px 0;
        .left {
          text-align: left;
          margin-top: 100px;
          .date-1 {
            width: 368px;
            height: 116px;
            background-size: 100% 100%;
          }
          .date-2 {
            font-size: 30px;
            font-weight: 300;
            color: #000000;
            margin-top: 45px;
            letter-spacing: 2px;
            &::after {
              content: "";
              display: inline-block;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 15px solid #634636;
              margin-left: 34px;
            }
          }
        }
        .right {
          width: 635px;
          text-align: right;
          position: relative;
          .bg-right {
            width: 249px;
            height: 249px;
            background-image: url("../assets/version2/other-16.png");
            background-size: 100% 100%;
            position: absolute;
            top: -100px;
            right: -100px;
            z-index: 0;
          }
          .bg-img {
            width: 635px;
            height: 424px;
            background-size: 100% 100%;
            position: relative;
            z-index: 1;
          }
          .en-img {
            display: inline-block;
            width: 451px;
            height: 69px;
            background-image: url("../assets/version2/other-17.png");
            background-size: 100% 100%;
            margin-top: 28px;
          }
          .name {
            font-size: 32px;
            font-weight: 400;
            color: #634636;
            letter-spacing: 2px;
            margin-top: 6px;
          }
          .text {
            font-size: 18px;
            font-weight: 300;
            color: #333333;
            line-height: 28px;
            margin-top: 24px;
            text-align: left;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}
</style>
