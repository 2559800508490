var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-wrap"},[_c('headNav',{attrs:{"active":"3"}}),_c('div',{staticClass:"common-contents"},[_c('div',{staticClass:"banner"},[(_vm.$i18n.locale == 'zh')?_c('img',{staticClass:"img",attrs:{"src":require("@/assets/version2/other-14.png"),"alt":"","srcset":""}}):_c('img',{staticClass:"img",attrs:{"src":require("@/assets/version2/otherEn-14.png"),"alt":"","srcset":""}})]),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"dot dot-right"}),_c('div',{staticClass:"dot dot-left"}),_c('div',{staticClass:"w1200 main"},[_c('div',{staticClass:"bg-line"}),_c('div',{staticClass:"item-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"flex between item item-1"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"date-1",style:({
                  backgroundImage:
                    'url(' +
                    require('../assets/version2/dsj-' +
                      _vm.templateTime(item.eventtime, 'year') +
                      '.png') +
                    ')',
                })}),_c('div',{staticClass:"date-2"},[_vm._v(_vm._s(_vm.templateTime(item.eventtime)))])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"bg-right"}),_c('div',{staticClass:"bg-img",style:({
                  backgroundImage: 'url(' + _vm.state.baseUrl + item.thumb + ')',
                })}),_c('div',{staticClass:"en-img"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text ellipsis2"},[_vm._v(" "+_vm._s(item.description)+" ")])])])}),0)])])]),_c('footNav',{attrs:{"type":"sub"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }