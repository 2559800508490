<template>
  <div class="common-wrap">
    <headNav active="1"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/other-8.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img
          src="@/assets/version2/otherEn-8.png"
          alt=""
          srcset=""
          class="img"
          v-else
        />
      </div>
      <div class="w1200">
        <!-- 党政办公室 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title2.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn2.png" alt="" v-else />
        </div>
        <div class="sub-list flex center">
          <div class="item" v-for="(item, key) in part2" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 艺术业务部 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title1.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn1.png" alt="" v-else />
        </div>
        <div class="sub-list flex center h-start">
          <div class="item" v-for="(item, key) in part1" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 演出营销部 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title3.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn3.png" alt="" v-else />
        </div>
        <div class="sub-list flex center">
          <div class="item" v-for="(item, key) in part3" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 品牌宣传部 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title9.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn9.png" alt="" v-else />
        </div>
        <div class="sub-list flex center h-start">
          <div class="item" v-for="(item, key) in part8" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 社会辅导部 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title7.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn7.png" alt="" v-else />
        </div>
        <div class="sub-list flex center h-start">
          <div class="item" v-for="(item, key) in part5" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 组织人事部 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title5.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn5.png" alt="" v-else />
        </div>
        <div class="sub-list flex center">
          <div class="item" v-for="(item, key) in part6" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 财务审计部 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title4.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn4.png" alt="" v-else />
        </div>
        <div class="sub-list flex center">
          <div class="item" v-for="(item, key) in part4" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 苏州民族管弦乐团音乐厅 -->
        <div class="sub-title tac">
          <img
            src="../assets/team/sub-title6.png"
            alt=""
            v-if="$i18n.locale == 'zh'"
          />
          <img src="../assets/team/sub-titleEn6.png" alt="" v-else />
        </div>
        <div class="sub-list flex center h-start">
          <div class="item" v-for="(item, key) in part7" :key="key">
            <div class="pic">
              <img
                :src="require(`../assets/team/${item.id}.png`)"
                alt=""
                class="img100"
              />
            </div>
            <div>
              <div v-if="$i18n.locale == 'zh'">
                <div class="name fs cmain fs18 pt-21 pb8 f-w-4">{{ item.name }}</div>
                <div class="py fs13 cmain">{{ item.py }}</div>
                <div class="py fs13 cmain">{{ item.py2 }}</div>
              </div>
              <div v-else style="max-width: 300px">
                <div class="name fs cmain fs18 pt-21">{{ item.py }}</div>
                <div class="name fs cmain fs18 pb8">{{ item.py2 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
const part1 = [
  {
    name: "平川 | 主任（兼）",
    py: "PING CHUAN",
    py2: "(concurrently) Director",
    id: "5-1",
  },
  {
    name: "董逸伦 | 常务副主任",
    py: "DONG YILUN",
    py2: "Executive Deputy Director",
    id: "1-2",
  },
  {
    name: "王曦 | 副主任（谱务主管）",
    py: "WANG XI",
    py2: "Deputy Director(Music Library Supervisor)",
    id: "1-4",
  },
  {
    name: "蒋宁宁 | 谱务",
    py: "JIANG NINGNING",
    py2: "Music Librarian",
    id: "1-6",
  },
  {
    name: "郭蔚 | 宣传主管",
    py: "GUO WEI",
    py2: "Promotion Supervisor",
    id: "1-3",
  },
  {
    name: "张祥 | 乐务主管",
    py: "ZHANG XIANG",
    py2: "Stage Supervisor",
    id: "1-5",
  },
];
const part2 = [
  {
    name: "谭苏亚 | 主任助理",
    py: "TAN SUYA",
    py2: "Assistant Director",
    id: "3-3",
  },
  {
    name: "张子昂 | 文秘主管 / 采购",
    py: "ZHANG ZI’ANG",
    py2: "Clerical Supervisors And Purchaser",
    id: "3-4",
  }
];

const part3 = [
  {
    name: "叶秦阳 | 主任(兼)",
    py: "YE QINYANG",
    py2: "Director(concurrently)",
    id: "3-1",
  },
  {
    name: "戴文婷 | 项目主管",
    py: "DAI WENTING",
    py2: "Project Manager",
    id: "3-2",
  },
];

const part4 = [
  {
    name: "陈金洋 | 主任",
    py: "CHEN JINYANG",
    py2: "Director",
    id: "4-1",
  },
  {
    name: "肖蓉 | 副主任",
    py: "XIAO RONG",
    py2: "Deputy Director",
    id: "4-3",
  },
];

const part5 = [
  {
    name: "时玉莹 | 常务副主任",
    py: "SHI YUYING",
    py2: "Executive Deputy Director",
    id: "5-5",
  },
  {
    name: "李昕瞳 | 教员",
    py: "LI XINTONG",
    py2: "Instructor",
    id: "5-2",
  },
  {
    name: "王家宝 | 教员",
    py: "WANG JIABAO",
    py2: "Instructor",
    id: "5-4",
  },
  {
    name: "许旭 | 教员",
    py: "XU XU",
    py2: "Instructor",
    id: "5-6",
  },
  {
    name: "许晨煜 | 教员",
    py: "XU CHENYU",
    py2: "Instructor",
    id: "5-9",
  },
  {
    name: "张宇航 | 教员",
    py: "ZHANG YUHANG",
    py2: "Instructor",
    id: "5-10",
  },
  {
    name: "李怡萱 | 教员",
    py: "LI YIXUAN",
    py2: "Instructor",
    id: "5-11",
  },
];

const part6 = [
  // {
  //   name: "周勇 | 主任（兼）",
  //   py: "ZHOU YONG",
  //   py2: "(concurrently) Director",
  //   id: "6-1",
  // },
  {
    name: "府靓娴 | 人事主管",
    py: "FU JINGXIAN",
    py2: "Personnel Manager",
    id: "4-2",
  },
];

const part7 = [
  {
    name: "叶秦阳 | 经理",
    py: "YE QINYANG",
    py2: "Manager",
    id: "3-1",
  },
  {
    name: "马王正 | 执行经理",
    py: "MA WANGZHENG",
    py2: "Executive Manager",
    id: "7-1",
  },
  {
    name: "陆梦洁 | 项目主管",
    py: "LU MENGJIE",
    py2: "Project Manager",
    id: "7-2",
  },
  {
    name: "陆晓旻 | 项目主管",
    py: "LU XIAOMIN",
    py2: "Project Manager",
    id: "7-3",
  },
  {
    name: "赵晋鹏 | 项目主管",
    py: "ZHAO JINPENG",
    py2: "Project Manager",
    id: "7-6",
  },
  {
    name: "祝孝军 | 灯光师",
    py: "ZHU XIAOJUN",
    py2: "Lighting Engineer",
    id: "7-7",
  },
  {
    name: "濮知我 | 项目主管",
    py: "PU ZHIWO",
    py2: "Project Manager",
    id: "7-8",
  },
];

const part8 = [
  {
    name: "叶秦阳 | 主任",
    py: "YE QINYANG",
    py2: "Director",
    id: "3-1",
  },
  {
    name: "徐馨儿 | 副主任",
    py: "XU XINER",
    py2: "Deputy Director",
    id: "8-1",
  },
  {
    name: "沈轩 | 项目主管",
    py: "SHEN XUAN",
    py2: "Project Manage",
    id: "8-3",
  }
];
export default {
  components: { headNav, footNav },
  data() {
    return {
      part1: part1,
      part2: part2,
      part3: part3,
      part4: part4,
      part5: part5,
      part6: part6,
      part7: part7,
      part8: part8,
    };
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  padding-bottom: 50px;
  background: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .sub-title {
    margin-top: 56px;
    img {
      height: 165px;
      display: inline-block;
    }
    &:first-child {
      margin-top: 106px;
    }
  }
  .sub-list {
    width: 960px;
    margin: 0 auto;
    padding-top: 52px;
    flex-wrap: wrap;
    align-items: baseline;
    .pic {
      width: 162px;
      height: 162px;
      background: #ccc;
      border-radius: 8px;
      overflow: hidden;
    }
    .item {
      min-width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}
</style>
