<template>
  <!-- 团讯 -->
  <div class="common-wrap">
    <headNav active="3"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/other-25.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img
          src="@/assets/version2/otherEn-25.png"
          alt=""
          srcset=""
          class="img"
          v-else
        />
      </div>
      <div class="contents">
        <div class="dot dot-right"></div>
        <div class="dot dot-left"></div>
        <div class="w1200 main flex between wrap">
          <div class="item" v-for="(item, index) in list" :key="index" @click="download(item)">
            <div class="top">
              <div
                class="img"
                :style="{ 'background-image': 'url(' + state.baseUrl + item.thumb + ')' }"
              ></div>
              <div class="bot">
                <div class="name">{{ item.title }}</div>
                <div class="btn">{{ $t("information.text4") }}</div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$ajax.get("/cms/api/tuanxunlist", {}).then((res) => {
        if (res.code == "200") {
          this.list = res.data || [];
        }
      });
    },
    download(item) {
      window.open(this.state.baseUrl + item.file, '_blank');
    },
    viewDetail(item) {
      this.$router.push({ name: "news-detail", params: { id: item.id }, query: {type: 'tuanxun'}});
    }
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .contents {
    position: relative;
    .dot {
      position: absolute;
      z-index: 99;
      background-image: url("../assets/version2/other-13.png");
      background-size: 100% 100%;
    }
    .dot-left {
      width: 284px;
      height: 475px;
      left: 0;
      bottom: 27px;
    }
    .dot-right {
      width: 243px;
      height: 648px;
      top: 148px;
      right: 0;
      transform: rotateY(180deg);
    }
    .main {
      position: relative;
      z-index: 100;
      padding-top: 1px;
      padding-bottom: 219px;
      &:after {
        content: "";
        width: 391px;
      }
      .item {
        width: 391px;
        text-align: center;
        margin-top: 107px;
        .top {
          cursor: pointer;
          .img {
            width: 100%;
            height: 450px;
            background-size: 100% 100%;
          }
          .bot{
            height: 180px;
            background: #634636;
            padding-top:40px;
            .name {
              color: #ffffff;
              font-size: 18px;
            }
            .btn {
              display: inline-block;
              width: 132px;
              line-height: 37px;
              opacity: 1;
              border: 1px solid #333333;
              border-radius: 8px;
              color: #333333;
              margin-top: 40px;
              cursor: pointer;
               background: #fff;
            }
          }
         
        }
      
      }
    }
  }
}
</style>
