<template>
  <div class="common-wrap">
    <headNav active="1"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img src="@/assets/version2/other-1.png" alt="" srcset="" class="img" v-if="$i18n.locale == 'zh'" />
        <img src="@/assets/version2/otherEn-1.png" alt="" srcset="" class="img" v-else />
      </div>
      <div class="w1200" v-if="$i18n.locale == 'zh'">
        <img src="@/assets/team/leader-1.png" class="mt-73 w1200" alt="" srcset="">
        <img src="@/assets/team/leader-2.png" class="mt-40 w1200" alt="" srcset="">
        <!-- <img src="@/assets/team/leader-3.png" class="mt-40 w1200" alt="" srcset=""> -->
        <img src="@/assets/team/leader-4.png" class="mt-40 w1200" alt="" srcset="">
        <div class="flex between mt-120">
          <img src="@/assets/team/leader-5-1.png" style="width: 340px;" alt="" srcset="">
          <img src="@/assets/team/leader-6-1.png" style="width: 340px;" alt="" srcset="">
          <img src="@/assets/team/leader-7-1.png" style="width: 340px;" alt="" srcset="">
        </div>
      </div>
      <div class="w1200" v-else>
        <img src="@/assets/team/leader-1-1.png" class="mt-73 w1200" alt="" srcset="">
        <img src="@/assets/team/leader-2-1.png" class="mt-40 w1200" alt="" srcset="">
        <!-- <img src="@/assets/team/leader-3-1.png" class="mt-40 w1200" alt="" srcset=""> -->
        <img src="@/assets/team/leader-4-1.png" class="mt-40 w1200" alt="" srcset="">
        <div class="flex between mt-120">
          <img src="@/assets/team/leader-5.png" style="width: 340px;" alt="" srcset="">
          <img src="@/assets/team/leader-6.png" style="width: 340px;" alt="" srcset="">
          <img src="@/assets/team/leader-7.png" style="width: 340px;" alt="" srcset="">
        </div>
      </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";

export default {
  components: { headNav, footNav },
};
</script>

<style scoped lang="scss">
.common-contents {
  background: #f2f6f7;
  font-family: Microsoft YaHei, Microsoft YaHei-Light;
  padding-bottom: 255px;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
}
</style>
